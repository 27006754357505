const DNI_REGEX = /^(\d{8})([A-Z])$/
const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/
const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/

function spainIdType (str) {
    if (str.match(DNI_REGEX)) {
      return 'dni'
    }
    if (str.match(CIF_REGEX)) {
      return 'cif'
    }
    if (str.match(NIE_REGEX)) {
      return 'nie'
    }
}

function validDNI (dni) {
   let dniLetters = 'TRWAGMYFPDXBNJZSQVHLCKE'
   let letter = dniLetters.charAt(parseInt(dni, 10) % 23)
        return letter === dni.charAt(8)
}

function validNIE (nie) {
    // Change the initial letter for the corresponding number and validate as DNI
   let niePrefix = nie.charAt(0)
    switch (niePrefix) {
      case 'X': niePrefix = 0; break
      case 'Y': niePrefix = 1; break
      case 'Z': niePrefix = 2; break
    }
    return validDNI(niePrefix + nie.substr(1))
}

function validCIF (cif) {
  if (!cif || cif.length !== 9) {
    return false
  }

  const letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  const digits = cif.substr(1, cif.length - 2)
  const letter = cif.substr(0, 1)
  const control = cif.substr(cif.length - 1)
  let sum = 0
  let i
  let digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return digit === parseInt(control)
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return digit === parseInt(control) || letters[digit] === control
}
export default function (item) {
    // Ensure upcase and remove whitespace
    let valid = false
    if (item) {
      const str = item.toUpperCase().replace(/\s/, '')
      let type = spainIdType(str)

      switch (type) {
          case 'dni':
            valid = validDNI(str)
            break
          case 'nie':
            valid = validNIE(str)
            break
          case 'cif':
            valid = validCIF(str)
            break
      }
    }
    return valid
}
