import moment from 'moment-timezone'
import { toDate, maybeParseTimestamp } from 'commons/js/util/dateConverter'

const getAge = (dateTimeStamp) => {
    const today = moment().startOf('day')
    const birthday = moment(toDate(maybeParseTimestamp(dateTimeStamp))).startOf('day')
    return today.diff(birthday, 'years')
}

export const getAgeDate = (date) => {
    const today = moment().startOf('day')
    const birthday = moment(date, 'YYYY-M-D', true).startOf('day')
    return today.diff(birthday, 'years')
}

export default getAge
