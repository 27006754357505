import validCnpj from './cnpj'
import validCpf from './cpf'
import validateCifnifnie from './cifnifnie'
import getAge, { getAgeDate } from './getAge'
import moment from 'moment-timezone'
import { getConstant } from 'commons/js/constants'
import { emailRegex } from '@elparking/form'
const UNDER_AGE = 18

const TEXT_VALIDATOR = getConstant('TEXT_VALIDATOR')
const CODE_VALIDATOR = getConstant('CODE_VALIDATOR')

// eslint-disable-next-line no-control-regex
const MAX_EMAIL_LENGTH = 63
const validateEmailLength = (email) => {
    if (!email) {
        return false
    }
    const firstPartOfEmail = email.split('@')[0]
    return firstPartOfEmail.length <= MAX_EMAIL_LENGTH
}
const FILTER_VALIDATE_EMAIL = emailRegex

export const hasSpecialCharacter = (str) => {
    return (/[^0-9A-Za-z]/.test(str))
}

export const password = () => (val = '') => {
    return !!/[A-Z]+/.test(val) &&
        !!/[a-z]+/.test(val) &&
        !!/[0-9]+/.test(val) &&
        (val.length >= 8) && (val.length < 100) &&
        !!/[^0-9A-Za-z]/.test(val) && hasSpecialCharacter(val)
}

// Sync validators
export const plateNumber = () => (val = '') => /^((\d{4}([b-dB-D]|[f-hF-H]|[j-nJ-N]|[p-tP-T]|[v-zV-Z]){3})|([a-zA-Z]{1,2}\d{4}[a-zA-Z]{1,2})|([a-zA-Z]{1,2}\d{6})|([a-zA-Z]\d{4})$)/.test(val)
export const maxBytesSize = (size) => (files) =>
!files || ([...files]).reduce((s, file) => s + file.size, 0) < size
export const checked = () => (val = false) => val
export const required = () => (val) => !!val && `${val}`.trim().length > 0
export const length = ({ min, max }) => (val = '') => !val || (val.length >= min && val.length <= max)
export const phone = () => (val = '') => !val || /^([+]|[0]{1})?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/.test(val)
export const email = () => (val = '') => !val || (FILTER_VALIDATE_EMAIL.test(val) && validateEmailLength(val))
export const number = () => (val = '') => /^[0-9]*$/.test(val)
export const postalCode = () => (val = null) => !val || /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$|^AD[1-7]00/.test(val)
export const postalCodeCostaRica = () => (val = null) => !val || /^[1-7][0-9]{4}$/.test(val)
export const cifnifnie = () => (val = '') => validateCifnifnie(val)
export const underAge = () => (val = '') => !val || getAge(val) >= UNDER_AGE
export const underAgeDate = () => (val = '') => !val || getAgeDate(val) >= UNDER_AGE
export const isValidDate = (format = 'DDMMYYYY') => (val = '') => !val || moment(val, format).isValid()
export const isPrevDate = (format = 'DDMMYYYY') => (val = '') => moment().isAfter(moment(val, format))
export const validCharacter = () => (val = '') => {
    if (val === '') {
        return true
    }
    const re = new RegExp(TEXT_VALIDATOR, 'u')
    return !!re.test(val)
}
export const validCode = () => (val = '') => {
    if (val === '') {
        return true
    }
    const reCode = new RegExp(CODE_VALIDATOR, 'u')
    return !!reCode.test(val)
}
export const sanitized = () => (val = '') => /^[^<>/\\'"“”‘’„”«»`¿?]+$/g.test(val)

// Async validators
export const available = ({ isAvailable }) => (val = '', done) => isAvailable(val).then(({ result: exists }) => done(!exists))
export const validCnpjOrCpf = () => (val = '') => validCnpj(val) || validCpf(val)
